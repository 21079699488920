import styled from "@emotion/styled";
import React from "react";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import { ROUTES } from "@stores/RouterStore";
import MainInfo from "@src/screens/card/MainInfo";
import SecondaryInfo from "@src/screens/card/SecondaryInfo";
import StoreLink from "@components/StoreLink";
import Logo from "@components/Header/Logo";
import { Row } from "@components/flex";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
`;
const CardScreen: React.FC<IProps> = () => {
  const { cardStore, accountStore } = useStores();
  const card = useObserver(() => cardStore.card);
  const user = useObserver(() => accountStore.user);
  if (card.direct != null && card.userId !== user?.firebase_uid) {
    window.location.replace(card.direct);
    return null;
  }
  if (
    card.settings?.domen != null &&
    card.userId !== user?.firebase_uid &&
    card.settings?.domen !== window.location.host
  ) {
    window.location.replace(
      `https://${card.settings?.domen}${window.location.pathname}`
    );
    return null;
  }
  return card.userId != null ? (
    <Root>
      <StoreLink props={card?.settings?.banner} />
      <MainInfo card={card} />
      <SecondaryInfo card={card} />
      {card?.settings?.logo != null && (
        <Row justifyContent="center">
          <Logo color="blue" />
        </Row>
      )}
    </Root>
  ) : (
    <Redirect to={ROUTES.REGISTER.replace(":cardId", card.id)} />
  );
};
export default CardScreen;
