import styled from "@emotion/styled";
import React, { useState } from "react";
import { ICard } from "@src/models";
import Header from "@components/Header";
import { layoutStyle } from "@components/Layout";
import SaveEditBtn from "@src/screens/card/SaveEditBtn";
import { Column, Row } from "@components/flex";
import { useStores } from "@stores";
import LinkComponent from "./LinkComponent";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  card: ICard;
}

const Root = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: #ebf0f8;
  border-radius: 0 0 25px 25px;
  ${layoutStyle};
  padding-bottom: 33px;
  box-sizing: border-box;
`;
const Gradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  height: 35px;
  width: 100%;
  border-radius: 0 0 25px 25px;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(235, 240, 248, 0) 12.94%,
    rgba(235, 240, 248, 0.85) 57.65%
  );
`;
const Img = styled.div<{ url?: string }>`
  width: 102px;
  height: 102px;
  border-radius: 15px;
  margin: 0 14px 0 5px;
  min-width: 102px;
  background-color: #2934d0;
  background-image: url(${({ url }) => url ?? "/assets/hands.svg"});
  background-size: ${({ url }) => (url != null ? "cover" : "contain")};
  background-repeat: no-repeat;
  background-position: center;
`;

const Links = styled.div<{ closed?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ closed }) => closed && "max-height: 130px"};
`;

const Name = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 12px;
`;

const Touches = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #6a81a6;
`;

const SeeMore = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #2934d0;
  cursor: pointer;
`;

const Promotion = styled.div`
  font-family: Graphik LCG;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 12px;
  padding: 12px;
  margin-left: 5px;
  margin-bottom: 12px;
`;

const MainInfo: React.FC<IProps> = ({ card }) => {
  const [closedLinks, setClosedLinks] = useState<boolean>(true);
  const { touchesStore } = useStores();
  const dictionary = useLanguage();
  const { photo, lastName, firstName, patronymicName, socialLinks, promoted } = card;
  return (
    <Root>
      <Header />
      {/*{promoted && <Promotion>Уникальный текст для этой карточки.</Promotion>}*/}
      <Row>
        <Img url={photo} />
        <Column>
          <Name>
            {lastName} <br />
            <span style={{ paddingTop: 5 }}>
              {firstName != null && firstName + " "}
              {patronymicName}
            </span>
          </Name>
          {touchesStore != null && (
            <Touches>{touchesStore.touchesString}</Touches>
          )}
        </Column>
      </Row>
      <SaveEditBtn style={{ margin: "0 5px" }} />
      <Links closed={closedLinks}>
        {socialLinks != null &&
          socialLinks.map((link, index) => (
            <LinkComponent {...link} key={index} />
          ))}
      </Links>
      <Gradient onClick={() => setClosedLinks(!closedLinks)}>
        {socialLinks!?.length > 4 && (
          <SeeMore>
            {closedLinks ? dictionary.seeMore : dictionary.hide}
          </SeeMore>
        )}
      </Gradient>
    </Root>
  );
};
export default MainInfo;
