import styled from "@emotion/styled";
import React from "react";
import { ReactComponent as Plus } from "@src/assets/smallPlus.svg";

interface IProps {
  onClick: () => void;
}

const Root = styled.div`
  display: flex;
  font-family: Graphik LCG, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  padding: 19px 0 23px;
  cursor: pointer;
`;

const AddButton: React.FC<IProps> = ({ onClick, children }) => {
  return (
    <Root onClick={onClick}>
      <Plus style={{ paddingRight: 10 }} />
      {children}
    </Root>
  );
};
export default AddButton;
