import styled from "@emotion/styled";
import React from "react";
import Title from "@components/fontComponents/Title";
import FieldsDescription from "@components/fontComponents/FieldsDescription";
import FieldValue from "@components/fontComponents/FieldValue";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  phones?: string[];
  emails?: string[];
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  & > * {
    padding: 1px 0;
  }
`;

const Contacts: React.FC<IProps> = ({ emails, phones }) => {
  const dictionary = useLanguage();
  if (emails?.length === 0 && phones?.length === 0) return null;
  return (
    <Root>
      <Title style={{ paddingBottom: 20 }}>{dictionary.contacts}</Title>
      {phones &&
        phones.map((phone, index) => (
          <div key={index}>
            <FieldsDescription>
              {phones.length > 1
                ? `${dictionary.phone} ` + (index + 1)
                : dictionary.phone}
            </FieldsDescription>
            <FieldValue
              style={{ color: "#0500ff" }}
              onClick={() => window.location.replace(`tel:${phone}`)}
            >
              {formatPhoneNumberIntl(phone as any)}
            </FieldValue>
          </div>
        ))}
      {emails &&
        emails.map((email, index) => (
          <div key={index}>
            <FieldsDescription>
              {emails.length > 1
                ? `${dictionary.email} ` + (index + 1)
                : dictionary.email}
            </FieldsDescription>
            <FieldValue
              style={{ color: "#0500ff" }}
              onClick={() => window.location.replace(`mailto:${email}`)}
            >
              {email}
            </FieldValue>
          </div>
        ))}
    </Root>
  );
};
export default Contacts;
